<template>
  <div class="disk-content-container">
    <div class="folder-table-area">
      <div
        class="operate-btn"
        :class="{ showBtn: !!this.selectFileList.length }"
      >
        <el-checkbox
          :indeterminate="indeterminate"
          v-model="isAllChecked"
          @change="handleCheckAllChange"
        ></el-checkbox>
        <span> {{ selectFileList.length }} Selected</span>
        <!-- <span class="btn">Download</span> -->
        <span class="btn" @click="handleBatchMoveInit">Move</span>
        <!-- <span class="btn" @click="handleBatchDownload">Download</span> -->
        <span class="btn delete" @click="handleBatchDelete">Delete</span>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :show-header="!selectFileList.length"
        :row-class-name="tableRowClassName"
        :cell-class-name="tableCellClassName"
        @cell-mouse-enter="handleCellMouseEnter"
        @cell-mouse-leave="handleCellMouseLeave"
        @cell-click="handleTableRowClick"
        class="is-desktop"
      >
        <!-- checkBox选择框 -->
        <el-table-column width="55">
          <template slot="header">
            <el-checkbox @change="handleCheckAllChange"></el-checkbox>
          </template>
          <template slot-scope="scope">
            <el-checkbox
              v-if="scope.row.isChecked || scope.row.isHover"
              v-model="scope.row.isChecked"
              @change="handleItemCheckChange(scope.row)"
            >
            </el-checkbox
          ></template>
        </el-table-column>
        <el-table-column label="NAME">
          <template slot-scope="scope">
            <i class="el-icon-folder" v-if="scope.row.entityType === 1"></i>
            <i class="el-icon-tickets" v-else></i>
            <span>{{ scope.row.folderName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="folderCreator" label="CREATOR" width="150">
        </el-table-column>
        <el-table-column prop="createDate" label="CREATION TIME" width="180">
          <template v-slot="scope">
            <span>{{ scope.row.createDate.replace(/-/g,'') }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="folderSize" label="大小" width="120">
        </el-table-column> -->
        <el-table-column width="80">
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              popper-class="operationPopover"
              :close-delay="100"
            >
              <div class="popover-content">
                <div
                  class="operation-item"
                  v-if="scope.row.entityType !== 0"
                  :class="{ disabled: downloadLoading }"
                  @click="!downloadLoading && handleDownLoad(scope.row)"
                >
                  <i class="el-icon-download"></i>
                  <span class="label">Download</span>
                </div>
                <div
                  class="operation-item"
                  @click="handleInitPermissonData(scope.row.id)"
                >
                  <i class="el-icon-lock"></i>
                  <span class="label">Permission</span>
                </div>
                <div class="operation-item" @click="handleRename(scope.row)">
                  <i class="el-icon-edit-outline"></i>
                  <span class="label">Rename</span>
                </div>
                <div
                  class="operation-item"
                  v-if="scope.row.entityType === 2"
                  @click="handleCopyPath(scope.row)"
                >
                  <i class="el-icon-copy-document"></i>
                  <span class="label">Copy Link</span>
                </div>
                <div
                  class="operation-item"
                  v-if="scope.row.entityType === 1"
                  @click="handleCopyPathFile(scope.row)"
                >
                  <i class="el-icon-copy-document"></i>
                  <span class="label">Copy Link</span>
                </div>
                <div class="operation-item" @click="handleMove(scope.row)">
                  <i class="el-icon-right"></i>
                  <span class="label">Move</span>
                </div>
                <div
                  class="operation-item delete"
                  @click="handleDelete(scope.row.id)"
                >
                  <i class="el-icon-delete"></i>
                  <span class="label">Delete</span>
                </div>
              </div>
              <span
                class="el-icon-more more-style"
                v-show="!isAllChecked"
                :class="{ notShow: !scope.row.isHover }"
                slot="reference"
              ></span>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <!-- 手机端 -->
      <div class="is-mobile mobile-table" style="font-size: 12px">
        <div v-for="line in tableData" :key="line.id" class="file">
          <div style="display: flex; align-items: center; width: 100%">
            <div style="flex-grow: 1; overflow: hidden" @click="open(line)">
              <div style="font-size: 2em">
                <i class="el-icon-folder" v-if="line.entityType === 1"></i>
                <i class="el-icon-tickets" v-else></i>
              </div>
              <div class="folder-name">{{ line.folderName }}</div>
              <div>{{ line.folderCreator }}</div>
              <div>{{ line.createDate }}</div>
            </div>
            <div style="width: 20px">
              <el-popover
                placement="right"
                trigger="click"
                popper-class="operationPopover"
              >
                <div class="popover-content">
                  <div
                    class="operation-item"
                    v-if="line.entityType !== 0"
                    :class="{ disabled: downloadLoading }"
                    @click="!downloadLoading && handleDownLoad(line)"
                  >
                    <i class="el-icon-download"></i>
                    <span class="label">Download</span>
                  </div>
                  <!-- <div class="operation-item">
                    <i class="el-icon-lock"></i>
                    <span
                      class="label"
                      @click="handleInitPermissonData(line.id)"
                      >Permission</span
                    >
                  </div> -->
                  <div class="operation-item" @click="handleRename(line)">
                    <i class="el-icon-edit-outline"></i>
                    <span class="label">Rename</span>
                  </div>
                  <div class="operation-item" @click="handleCopyPath(line)">
                    <i class="el-icon-copy-document"></i>
                    <span class="label">Copy Path</span>
                  </div>
                  <!-- <div class="operation-item">
                    <i class="el-icon-right"></i>
                    <span class="label" @click="handleMove(line)">Move</span>
                  </div> -->
                  <div
                    class="operation-item delete"
                    @click="handleDelete(line.id)"
                  >
                    <i class="el-icon-delete"></i>
                    <span class="label">Delete</span>
                  </div>
                </div>
                <span
                  style="
                    font-size: 2em;
                    transform: rotate(90deg);
                    color: #9e9e9f;
                  "
                  class="el-icon-more"
                  slot="reference"
                  v-if="!isAllChecked"
                />
              </el-popover>
            </div>
          </div>
        </div>
      </div>
      <!-- 权限管理的dialog -->
      <el-dialog
        title="Permission Management"
        :visible.sync="authDialogVisible"
        append-to-body
        width="35%"
        custom-class="authSettingDialog"
        @close="handleAuthClosed"
      >
        <div class="dialog-content" v-if="authDialogVisible">
          <AuthPermission :storageFileId="currentFolderId" />
        </div>
      </el-dialog>

      <!-- 权限管理的dialog -->
      <el-dialog
        title="MOVE"
        :visible.sync="moveDialogVisible"
        append-to-body
        width="35%"
        custom-class="moveSettingDialog"
        @close="handleMoveClosed"
      >
        <div class="dialog-content" v-if="moveDialogVisible">
          <EntityMove
            :isMultiple="isMultiple"
            @closeMoveDialogEvent="moveDialogVisible = false"
            @moveEntityEvent="handleEntityMoveSave"
          />
        </div>
      </el-dialog>
    </div>

    <!-- 图片预览 -->
    <div class="img-preview">
      <el-image-viewer
        class="yunPreview"
        v-if="imgViewerVisible"
        :on-close="closeImgViewer"
        :url-list="imgList"
        @click="closeImgViewer"
        @mousemove.native="closeImg"
        :initialIndex="initialIndex"
        :onSwitch="testSwitch"
        :zIndex="1000"
        :appendToBody="false"
        :class="{
          prevNotShow: initialIndex === 0,
          nextNotShow: initialIndex === originImgList.length - 1,
        }"
        @mousewheel.native.stop="testWheel"
        ref="imageViewerref"
      />
      <div class="preview-name" v-if="imgViewerVisible">
        <span>{{
          originImgList.length ? originImgList[initialIndex].folderName : ""
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Message, MessageBox } from 'element-ui'
import AuthPermission from '../components/AuthPermission.vue'
import EntityMove from '../components/EntityMove.vue'

import {
  getFolderAndFileList,
  deleteFolderAndFile,
  updateFolderAndFileName,
  batchDeleteFolderAndFile,
  moveFolderAndFile,
  batchMoveFolderAndFile,
  downloadFileOrFolderNew,
  getCodeById
} from '../api/index'

export default {
  data() {
    return {
      tableLoading: false,
      tableData: [],
      selectFileList: [],
      isAllChecked: false,
      testValue: true,
      currentFolderId: null,
      authDialogVisible: false,
      moveDialogVisible: false,
      isMultiple: false,
      currentMoveFolderId: null,
      imgViewerVisible: false,
      imgList: [],
      downloadLoading: false,
      breadcrumbList: [],

      originImgList: [],
      initialIndex: 0,
      originDelta: 0
    }
  },
  components: {
    AuthPermission,
    EntityMove,
    'el-image-viewer': () =>
      import('element-ui/packages/image/src/image-viewer')
  },
  created() {
    const id = this.$route.query.id || null
    this.getFolderAndFileDataList(null, null, id)
  },
  mounted() {
    this.$bus.$on('clickBreadcrumbEvents', (data) => {
      // console.log('面包屑事件', data)
      const { id } = data
      this.getFolderAndFileDataList(null, null, id)
    })
    this.$bus.$on('updataCurrentFolderDataEvent', () => {
      this.getFolderAndFileDataList(null, null, this.currentFolderId)
    })
  },
  beforeDestroy() {
    this.$bus.$off('clickBreadcrumbEvents')
    this.$bus.$off('updataCurrentFolderDataEvent')
  },
  computed: {
    indeterminate() {
      return !!(
        this.selectFileList.length &&
        this.selectFileList.length < this.tableData.length
      )
    }
  },
  methods: {
    /**
     * @description: 文件夹或者文件移动
     * @param {*} breadcrumbList
     * @return {*}
     */
    async handleEntityMoveSave(breadcrumbList) {
      if (this.selectFileList.length) {
        this.handleBatchEntityMoveSave(breadcrumbList)
        return
      }
      const sendData = {
        id: this.currentMoveFolderId,
        parentId: breadcrumbList[breadcrumbList.length - 1].id
      }
      const res = await moveFolderAndFile(sendData)
      if (res.code === 200) {
        Message({
          type: 'success',
          message: '移动成功'
        })
        this.moveDialogVisible = false
        // 刷新页面 todolist
        this.getFolderAndFileDataList(null, null, this.currentFolderId)
      } else {
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },
    /**
     * @description: 批量移动数据初始化
     * @return {*}
     */
    handleBatchMoveInit() {
      this.moveDialogVisible = true
      this.isMultiple = true
    },
    /**
     * @description: 文件夹或者文件批量移动
     * @param {*} breadcrumbList
     * @return {*}
     */
    async handleBatchEntityMoveSave(breadcrumbList) {
      const sendData = {
        idList: this.selectFileList.map((item) => item.id),
        parentId: breadcrumbList[breadcrumbList.length - 1].id
      }
      const res = await batchMoveFolderAndFile(sendData)
      if (res.code === 200) {
        Message({
          type: 'success',
          message: '移动成功'
        })
        this.getFolderAndFileDataList(null, null, this.currentFolderId)
        this.moveDialogVisible = false
        this.selectFileList = []
      } else {
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },
    /**
     * @description: 批量删除
     * @return {*}
     */
    handleBatchDelete() {
      MessageBox.confirm(
        'Are you sure you want to delete these file or folder?',
        'Delete',
        {
          confirmButtonText: 'SURE',
          cancelButtonText: 'CANCEL',
          type: 'warning'
        }
      )
        .then(async () => {
          // console.log(this.selectFileList)
          const idList = this.selectFileList.map((item) => item.id)
          const res = await batchDeleteFolderAndFile({ idList })
          if (res.code === 200) {
            Message({
              type: 'success',
              message: 'operate successfully'
            })
            this.getFolderAndFileDataList(null, null, this.currentFolderId)
            this.selectFileList = []
          } else {
            Message({
              type: 'error',
              message: res.message ? res.message : 'Server Error!'
            })
          }
        })
        .catch(() => {
          Message({
            type: 'info',
            message: 'Cancel Delete!'
          })
        })
    },
    /**
     * @description: 批量下载功能
     * @return {*}
     */
    async handleBatchDownload() {
      // console.log(this.selectFileList)
      const idList = this.selectFileList.map((item) => item.id)
      return
      const res = await batchDeleteFolderAndFile({
        idList,
        bucketName:
          process.env.NODE_ENV === 'prod' ? 'stdisk-prod' : 'clouddisk-qa'
      })
      if (res.code === 200) {
        Message({
          type: 'success',
          message: 'operate successfully'
        })
      } else {
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },
    /**
     * @description:获取云盘数据
     * @return {*}
     */
    async getFolderAndFileDataList(
      entityId = null,
      entityType = null,
      parentId = null,
      orderField = 1,
      orderType = true,
      isLayered = true
    ) {
      const sendData = {
        entityId,
        entityType,
        parentId,
        orderField,
        orderType,
        isLayered
      }
      this.currentFolderId = parentId
      // this.tableLoading = true
      this.$emit('tableLoading', true)
      const res = await getFolderAndFileList(sendData)
      if (res.code === 200) {
        // console.log(res.data)
        this.tableData = res.data.map((item) => {
          // console.log(item)
          return {
            // ...item,
            folderCreator: item.user
              ? item.user.englishName
                ? item.user.englishName
                : item.user.firstNamePy && item.user.lastNamePy
                  ? item.user.lastNamePy + item.user.firstNamePy
                  : item.user.chineseName
              : '',
            folderName: item.fileName,
            createDate: this.getFormatCreateTime(item.createTime),
            id: item.id,
            entityType: item.entityType,
            entityId: item.entityId,
            parentId: item.parentId,
            purviewType: item.purviewType,
            isHover: false,
            isChecked: false,
            suffixString: this.getFileExtension(item.fileName)
          }
        })

        // 处理所有的图片
        const picArray = ['png', 'svg', 'jpg', 'jpeg', 'gif']
        this.originImgList = this.tableData.filter((item) => {
          return item.entityType === 2 && picArray.includes(item.suffixString)
        })
        // console.log(this.originImgList)
        if (this.originImgList.length) {
          const allUrl = await Promise.all(
            this.originImgList.map(
              async (item) => await this.getPreviewUrl(item)
            )
          )
          // console.log(allUrl, '============')
          this.imgList = allUrl
        }

        this.$emit('tableLoading', false)
      } else {
        this.$emit('tableLoading', false)
        Message({
          type: 'error',
          message: res.message ? res.message : 'Server Error!'
        })
      }
    },

    /**
     * @description:
     * @param {*} filename
     * @return {*}
     */
    getFileExtension(filename) {
      const match = filename.match(/\.([a-z]+)$/i)
      return match ? match[1].toLowerCase() : ''
    },

    /**
     * @description: 格式化创建时间
     * @param {*} time
     * @return {*}
     */
    getFormatCreateTime(time) {
      if (!time) return ''
      const formatTime = time.replaceAll('T', ' ').split(':')
      return formatTime[0] + ':' + formatTime[1]
    },
    /**
     * @description: 处理全部选中数据
     * @return {*}
     */
    handleCheckAllChange(val) {
      // console.log(val)
      this.isAllChecked = val
      this.tableData.forEach((item) => {
        item.isChecked = val
      })
      this.selectFileList = val ? this.tableData.map((item) => item) : []
    },
    /**
     * @description: 处理选中数据
     * @return {*}
     */
    handleItemCheckChange(row) {
      if (!row.isChecked) {
        this.selectFileList.splice(
          this.selectFileList.map((ele) => ele.id).indexOf(row.id),
          1
        )
      } else {
        this.selectFileList.push(row)
      }
      this.isAllChecked = this.selectFileList.length === this.tableData.length
    },
    /**
     * @description: 行样式添加
     * @param {*} row
     * @param {*} rowIndex
     * @return {*}
     */
    tableRowClassName({ row }) {
      const idList = this.selectFileList.map((item) => item.id)
      if (idList.indexOf(row.id) > -1 || row.isChecked) {
        return 'selected'
      }
    },
    /**
     * @description: 表格样式添加
     * @return {*}
     */
    tableCellClassName({ columnIndex }) {
      // console.log(columnIndex)
      if (columnIndex === 1) {
        return 'folderName'
      } else if (columnIndex === 5) {
        return 'folderOperateBtn'
      } else {
        return 'babyFontColor'
      }
    },
    /**
     * @description: 鼠标进入单元格
     * @return {*}
     */
    handleCellMouseEnter(row) {
      // console.log(row)

      row.isHover = true
    },
    /**
     * @description: 鼠标离开单元格
     * @return {*}
     */
    handleCellMouseLeave(row) {
      // console.log(row)
      row.isHover = false
    },
    /**
     * @description: 点击文件/文件夹
     * @return {*}
     */
    handleTableRowClick(row, clo) {
      // 点击文件夹
      // console.log(row, clo)
      if (clo.label === 'NAME' && row.entityType === 1) {
        this.currentFolderId = row.id
        // this.$bus.$emit('setBreadcrumbEvent', row)
        this.getFolderAndFileDataList(null, row.entityType, row.id)
        this.$router.push({
          params: {
            folder: row.folderName
          },
          query: {
            id: row.id
          }
        })
      } else if (clo.label === 'NAME' && row.entityType === 2) {
        // 打开预览文件页面
        // console.log('跳转预览文件页面')
        this.handlePreviewFile(row)
      }
    },
    open(line) {
      if (line.entityType === 1) {
        this.currentFolderId = line.id
        // this.$bus.$emit('setBreadcrumbEvent', line)
        this.getFolderAndFileDataList(null, line.entityType, line.id)
        this.$router.push({
          params: {
            folder: line.folderName
          },
          query: {
            id: line.id
          }
        })
      } else if (line.entityType === 2) {
        this.handlePreviewFile(line)
      }
    },

    /**
     * @description: 获取预览和下载url
     * @param {*} item
     * @return {*}
     */
    async getPreviewUrl(item) {
      const res = await downloadFileOrFolderNew({
        id: item.id
      })
      const { code, data, message } = res
      if (code === 200) {
        return data
      } else {
        Message({
          type: 'error',
          message: message || 'Server Error'
        })
        return null
      }
    },

    /**
     * @description: 预览文件
     * @param {*} item
     * @return {*}
     */
    async handlePreviewFile(item) {
      const viewUrl = await this.getPreviewUrl(item)
      // return
      const fileArray = ['docx', 'xlsx', 'pptx', 'doc']
      const picArray = ['png', 'svg', 'jpg', 'jpeg', 'gif']
      const pdfArray = ['pdf', 'mov', 'mp4']
      const fileName = item.folderName
      const fileTypeString = fileName
        .substring(fileName.lastIndexOf('.') + 1)
        .toLowerCase()
      // console.log(fileTypeString, fileName)

      if (fileArray.indexOf(fileTypeString) > -1) {
        const url = encodeURIComponent(viewUrl)
        const officeUrl =
          'https://view.officeapps.live.com/op/view.aspx?src=' + `${url}`
        // 在新窗口打开编码后 的链接
        window.open(officeUrl, '_target')
      } else {
        if (picArray.indexOf(fileTypeString) > -1) {
          // console.log('图片', viewUrl)
          // 展示图片 预览，点击消失
          this.initialIndex = this.originImgList.findIndex(
            (ele) => ele.id === item.id || 0
          )
          this.imgViewerVisible = true
          this.stopMove()
        } else if (pdfArray.indexOf(fileTypeString) > -1) {
          window.open(viewUrl, '_target')
        } else {
          this.$notify({
            message: '当前不支持该文件的预览功能',
            type: 'warning',
            position: 'bottom-left',
            duration: 3000
          })
        }
      }
    },
    testSwitch(value) {
      // console.log(value)
      this.initialIndex = value
    },
    testWheel(value) {
      this.originDelta += value.wheelDelta
      if (this.originDelta >= 240) {
        if (this.initialIndex - 1 >= 0) {
          this.$refs.imageViewerref.prev()
          this.originDelta = 0
        }
      } else if (this.originDelta <= -240) {
        if (this.initialIndex + 1 <= this.originImgList.length - 1) {
          this.$refs.imageViewerref.next()
        }
        this.originDelta = 0
      }
    },
    closeImgViewer() {
      this.imgViewerVisible = false
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'auto'
      document.removeEventListener('touchmove', m, true)
      this.move()
    },
    closeImg(e) {
      // console.log(e)
      const that = this
      if (e.target.currentSrc) {
        e.target.addEventListener('click', function () {
          that.imgViewerVisible = false
        })
      }
    },
    // 停止页面滚动
    stopMove() {
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', m, false) // 禁止页面滑动
    },

    // 开启页面滚动
    move() {
      const m = (e) => {
        e.preventDefault()
      }
      document.body.style.overflow = 'auto'
      document.removeEventListener('touchmove', m, true)
    },
    /**
     * @description: 删除文件或者文件夹
     * @return {*}
     */
    handleDelete(id) {
      // console.log(id)
      MessageBox.confirm(
        'Are you sure you want to delete this file or folder?',
        'Delete',
        {
          confirmButtonText: 'SURE',
          cancelButtonText: 'CANCEL',
          type: 'warning'
        }
      )
        .then(async () => {
          const res = await deleteFolderAndFile({ id })
          if (res.code === 200) {
            Message({
              type: 'success',
              message: res.message
            })
            this.getFolderAndFileDataList(null, null, this.currentFolderId)
          } else {
            Message({
              type: 'error',
              message: res.message ? res.message : 'Server Error!'
            })
          }
        })
        .catch(() => {
          Message({
            type: 'info',
            message: 'Cancel Delete!'
          })
        })
    },
    /**
     * @description: 重命名文件夹或者文件
     * @return {*}
     */
    handleRename(item) {
      // console.log(item);
      MessageBox.prompt('Please enter a file or folder name', 'Rename', {
        confirmButtonText: 'SAVE',
        cancelButtonText: 'CANCEL',
        inputValue: item.folderName
      })
        .then(async ({ value }) => {
          const res = await updateFolderAndFileName({
            id: item.id,
            fileName: value,
            entityType: item.entityType,
            parentId: item.parentId
          })
          if (res.code === 200) {
            Message({
              type: 'success',
              message: res.message
            })
            this.getFolderAndFileDataList(null, null, this.currentFolderId)
          } else {
            Message({
              type: 'error',
              message: res.message ? res.message : 'Server Error!'
            })
          }
        })
        .catch(() => {
          Message({
            type: 'info',
            message: 'Cancel Rename!'
          })
        })
    },
    /**
     * @description: 复制文件的链接
     * @return {*}
     */
    async handleCopyPath(data) {
      // console.log(data)
      const { id, folderName } = data

      // console.log('根据id获取预览code')
      const res = await getCodeById({ id })
      if (res.code === 200) {
        try {
          await navigator.clipboard.writeText(
            encodeURI(
              `${process.env.VUE_APP_DOMAIN}yun/${
                res.data
              }/${folderName.replaceAll("'", '‘')}`
            )
          )
          this.$message({
            type: 'success',
            message: 'Copied'
          })
        } catch ($e) {}
      } else {
        this.$message({
          type: 'error',
          message: res.message || 'server error'
        })
      }
    },
    async handleCopyPathFile(data) {
      const { id, folderName } = data
      try {
        await navigator.clipboard.writeText(
          encodeURI(
            `${process.env.VUE_APP_DOMAIN}cloudStorage/${folderName}?id=${id}`
          )
        )
        this.$message({
          type: 'success',
          message: 'Copied'
        })
      } catch ($e) {}
    },
    /**
     * @description: 文件和文件夹下载
     * @return {*}
     */
    async handleDownLoad(item) {
      this.$emit('downloadLoading', true)
      this.downloadLoading = true
      const viewUrl = await this.getPreviewUrl(item)
      console.log(viewUrl)
      if (viewUrl) {
        this.$emit('downloadLoading', false)
        this.downloadLoading = false
        const link = document.createElement('a')
        link.href = viewUrl
        link.download = item.folderName
        link.name = item.folderName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        this.downloadLoading = false
        this.$emit('downloadLoading', false)
      }
    },
    /**
     * @description: 移动
     * @return {*}
     */
    handleMove(item) {
      this.currentMoveFolderId = item.id
      // console.log(item)
      this.moveDialogVisible = true
    },
    /**
     * @description: 权限dialog关闭
     * @return {*}
     */
    handleAuthClosed() {
      this.authDialogVisible = false
    },
    /**
     * @description: 初始化dialog数据
     * @return {*}
     */
    handleInitPermissonData(id) {
      this.currentFolderId = id
      this.authDialogVisible = true
    },
    /**
     * @description: 移动dialog关闭
     * @return {*}
     */
    handleMoveClosed() {
      this.moveDialogVisible = false
      this.isMultiple = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/color.scss";
.disk-content-container {
  margin: 10px 0;
  height: calc(100% - 20px);
  overflow: auto;
  .folder-table-area::v-deep {
    height: 100%;
    .operate-btn {
      display: none;
      transition: all 0.3s ease-out;

      span {
        margin-right: 16px;
      }

      .el-checkbox__input {
        margin-right: 0px;
        margin-left: 10px;
      }

      .btn {
        border: 1px solid $greyBg;
        padding: 4px 10px;
        border-radius: 2px;
        color: $mainFontColor;
        cursor: pointer;
      }

      .delete {
        background: $mainRed;
        color: $white;
        border: none;
      }
    }

    .showBtn {
      height: 48px;
      line-height: 48px;
      display: block;
    }

    .el-table {
      cursor: default;

      tr {
        height: 50px;
        line-height: 50px;
      }

      .el-table__body-wrapper {
        box-sizing: border-box;
        border-top: 1px solid #f1f1f0;
        table {
          font-size: 13px;
        }
      }

      .el-table__cell {
        border: none;
      }

      thead {
        color: $lowFontColor !important;
        font-weight: normal !important;

        th {
          font-weight: normal !important;

          .cell {
            font-size: 12px;
            @media (max-width: 768px) {
              font-size: 13px;
            }
          }
        }
      }
    }

    .el-table::before {
      display: none;
    }

    // .noHeader .el-table__header-wrapper {
    //   display: none;
    // }
    .el-table .selected {
      background: $babyBlue;
    }

    .el-table .folderName {
      color: $mainFontColor;
      font-size: 15px;

      .cell {
        cursor: pointer;
        display: flex;
        align-items: center;

        &:hover {
          color: $mainBlue;
        }

        i {
          // color: $mainBlue;
          color: #cfcecb;
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }

    .el-table .folderOperateBtn {
      color: $greyBg;
      font-size: 16px;

      :hover {
        cursor: pointer;
        color: $mainFontColor !important;
      }
    }

    .el-table .babyFontColor {
      color: $babyFontColor;
    }

    .el-popover__reference-wrapper {
      cursor: pointer;

      &:hover {
        color: $mainBlue;
      }
      .el-popover__reference {
        padding: 20rpx !important;
      }
    }
  }

  .img-preview::v-deep {
    .prevNotShow {
      .el-image-viewer__prev {
        display: none;
      }
    }
    .nextNotShow {
      .el-image-viewer__next {
        display: none;
      }
    }
  }
  .preview-name {
    position: fixed;
    top: 85px;
    left: 65px;
    z-index: 3000;
    span {
      font-size: 14px;
      background: #fff;
      padding: 10px 22px;
      border-radius: 30px;
      color: #2472b3;
      // font-weight: 600;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    @media (max-width: 768px) {
      left: 0;
      right: 0;
      max-width: 300px;
      margin: 0 auto;
      span {
        display: inline-block;
        width: calc(100% - 44px);
      }
    }
  }
}
</style>

<style lang="scss">
@import "../styles/color.scss";
@media (max-width: 768px) {
  .el-message-box__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .el-message-box {
      width: 90%;
    }
  }
}
.operationPopover {
  // width: 200px;
  min-width: 140px !important;
  padding: 8px 0;
  border-radius: 8px !important;
  .popover-content {
    .operation-item {
      height: 36px;
      line-height: 36px;
      display: flex;
      align-items: center;
      padding: 0 24px;
      cursor: pointer;
      i {
        font-size: 16px;
        margin-right: 10px;
      }
      span {
        cursor: pointer;
      }
      &:hover {
        // background: $lowGreyBg;
        i,
        span {
          color: $mainBlue;
        }
      }
    }
    .operation-item.disabled {
      pointer-events: none; /* 禁用点击事件 */
      opacity: 0.5; /* 设置半透明效果 */
    }
    .delete {
      border-top: 1px solid $greyBg;
      color: $mainRed;
      &:hover {
        i,
        span {
          color: $mainRed;
        }
      }
    }
  }
  .popper__arrow {
    display: none;
  }
}
.moveSettingDialog {
  min-width: 700px;
}
@media (max-width: 768px) {
  .mobile-table {
    .file {
      background: #fff;
      padding: 12px 16px;
      border-radius: 8px;
      margin-bottom: 8px;
      color: #9e9e9f;
      .folder-name {
        color: #333;
        font-family: MontserratSemibold;
        font-size: 14px;
        margin: 10px 0;
      }
      .folder-name {
        width: 100%;
        -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
        -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
        overflow: hidden; /* 隐藏超出范围的文本 */
        text-overflow: ellipsis; /* 规定当文本溢出元素框时发生的事情 */
      }
    }
  }
}
</style>
<style lang="scss">
.disk-content-container {
  .more-style {
    font-size: 16px;
    padding: 4px 0px !important;
    box-sizing: border-box !important;
    opacity: 1;
  }
  .notShow {
    opacity: 0;
  }
}
</style>
