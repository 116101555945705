export function getFormatRouterlist(path) {
  const result = []
  result.push(...path.split('/'))
  result.pop()
  result.shift()
  result.shift()
  // console.log(result)
  return result
}
export function formatLabel(label) {
  if (!label) {
    return ''
  }
  return label.replace(/\s+/g, '-').replace(/[\(\)\（\）]/g, '-')
}

export function getAllSchoolData() {
  const allSchoolIdList = [2, 3, 4, 6, 7, 8, 9]
  return {
    allSchoolIdList,
    allSchoolInfoData: allSchoolIdList.map((item) => {
      return {
        id: item,
        name: getFormatSchoolName(Number(item))
      }
    })
  }
}
export function getCampusSyncList() {
  return [
    {
      label: 'BEIJING',
      id: 2
    },
    {
      label: 'SHANGHAI',
      id: 3
    },
    {
      label: 'SUZHOU',
      id: 4
    },
    {
      label: 'HANGZHOU',
      id: 7
    },
    {
      label: 'MILAN',
      id: 8
    },
    {
      label: 'MELBOURNE',
      id: 9
    },
    {
      label: 'CHENGDU',
      id: 10
    }
  ]
}
export function getFormatSchoolName(id) {
  switch (id) {
    case 6:
      return '总部'
    case 2:
      return '北京'
    case 3:
      return '上海'
    case 4:
      return '苏州'
    case 7:
      return '杭州'
    case 8:
      return '米兰'
    case 9:
      return '墨尔本'
    case 10:
      return '成都'
  }
}
export function getCommonSchoolIdList(arr, list) {
  if (arr && list) {
    // console.log(arr, list)
    const newList = []
    arr.forEach((item) =>
      list.forEach((ele) => {
        if (item === ele) {
          newList.push(item)
        }
      })
    )
    console.log(newList)
    return newList
  }
}

export function getCommonFormatSchoolInfoList(arr, list) {
  if (arr && list) {
    // console.log(arr, list)
    const newList = []
    arr.forEach((item) =>
      list.forEach((ele) => {
        if (item === ele) {
          newList.push(item)
        }
      })
    )
    console.log(newList)
    return newList.map((item) => {
      return {
        id: item,
        name: getFormatSchoolName(Number(item))
      }
    })
  }
}

// 下载文件
export function dowmLoadFile(url, fileName) {
  getBlob(url).then((blob) => {
    DownloadItem(blob, fileName)
  })
}
function getBlob(url) {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response)
      }
    }
    xhr.send()
  })
}
function DownloadItem(blob, fileName) {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// 标准时间格式获取
export function getFormatDate(dateValueue, sq) {
  const nowDate = new Date(dateValueue)
  const nowYear = nowDate.getFullYear()
  const nowMonth =
    nowDate.getMonth() + 1 < 10
      ? '0' + (nowDate.getMonth() + 1)
      : nowDate.getMonth() + 1
  const nowDay =
    nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate()
  const nowHour =
    nowDate.getHours() < 10 ? '0' + nowDate.getHours() : nowDate.getHours()
  const nowMin =
    nowDate.getMinutes() < 10
      ? '0' + nowDate.getMinutes()
      : nowDate.getMinutes()
  const nowSec =
    nowDate.getSeconds() < 10
      ? '0' + nowDate.getSeconds()
      : nowDate.getSeconds()
  if (sq) {
    return (
      nowYear +
      sq +
      nowMonth +
      sq +
      nowDay +
      ' ' +
      nowHour +
      ':' +
      nowMin +
      ':' +
      nowSec
    )
  } else {
    return (
      nowYear +
      '-' +
      nowMonth +
      '-' +
      nowDay +
      ' ' +
      nowHour +
      ':' +
      nowMin +
      ':' +
      nowSec
    )
  }
}

// 防抖
export const Debounce = (fn, t) => {
  const delay = t || 500
  let timer
  return function () {
    const args = arguments
    if (timer) {
      clearTimeout(timer)
    }

    const callNow = !timer

    timer = setTimeout(() => {
      timer = null
    }, delay)

    if (callNow) fn.apply(this, args)
  }
}

export const DebounceLast = (fn, t) => {
  let timeout = null
  return function () {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      fn.apply(this, arguments)
    }, t)
  }
}

export function imgToBase64(url, cb) {
  let canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const img = new Image()

  img.crossOrigin = 'Anonymous'
  img.onload = function () {
    canvas.height = img.height
    canvas.width = img.width
    ctx.drawImage(img, 0, 0)
    const dataURL = canvas.toDataURL('image/png')
    canvas = null
    // return cb && cb(dataURL)
    if (cb) {
      return cb(dataURL)
    }
  }
  img.src = url
}
// base64转file对象
export function base64toFile(base, filename) {
  const arr = base.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const suffix = mime.split('/')[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  // 转换成file对象
  return new File([u8arr], `${filename}.${suffix}`, { type: mime })
}

export function debounceTail(func, wait) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      func.apply(context, args)
      timeout = null // 清除timeout变量，确保下次调用时会再次设置timeout
    }, wait)
  }
}

// 判断用户是否离职
export async function judgeUserResigned() {
  const url =
    process.env.NODE_ENV === 'prod'
      ? 'https://api.nihaocafe.com/SmartLingo/login/isValid'
      : 'https://testapi.nihaocafe.com/SmartLingo/login/isValid'
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: JSON.parse(localStorage.getItem('User_Token')) || ''
      }
    })
    const res = await response.json()
    const { code, data } = res
    if (code === 200 && !data) {
      window.localStorage.clear()
      sessionStorage.clear()
      location.reload()
    }
  } catch (error) {
    // console.log(error, '=====================')
  }
}

// 工具函数：移除 x-oss-process 参数
// utils/index.js
export function removeXOSSParams(url) {
  const index = url.indexOf('?x-oss-process=image')
  if (index !== -1) {
    return url.slice(0, index)
  }
  return url
}
/**
 * @description: 压缩图片
 * @param {*} url
 * @return {*}
 */
export function addQualityParam(url) {
  if (!url) return
  if (!url.includes('static.nihaocafe.com')) return url // 仅处理阿里云图片
  const separator = url.includes('?') ? '&' : '?'

  return `${url}${separator}x-oss-process=image/format,webp`
}
