<template>
  <div id="app">
    <router-view />
    <div class="border-chat">
      <ChatBot v-if="getToken && $route.fullPath.includes('knowledgeBase')" :key="getToken" />
    </div>
  </div>
</template>

<script>
import { judgeUserResigned } from '@/utils/tools'

export default {
  components: {
    ChatBot: () => import('@/components/ChatBot/index.vue').catch(error => {
      console.error('Failed to load ChatBot component:', error)
      return null
    })
  },
  data() {
    return {
      timer: null,
      chatShowList: [
        ''
      ]
    }
  },
  created() {
    this.initUserResignedCheck()
  },
  computed: {
    getToken() {
      return this.$store.state.token || localStorage.getItem('User_Token')
    }
  },
  beforeDestroy() {
    this.clearUserResignedCheck()
  },
  methods: {
    initUserResignedCheck() {
      if (localStorage.getItem('User_Data')) {
        this.runUserResignedCheck()
        this.timer = setInterval(() => {
          this.runUserResignedCheck()
        }, 300000)
      }
    },
    runUserResignedCheck() {
      try {
        judgeUserResigned()
      } catch (error) {
        console.error('Error in judgeUserResigned:', error)
      }
    },
    clearUserResignedCheck() {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100vh;
}
</style>
