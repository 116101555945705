var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view"),
      _c(
        "div",
        { staticClass: "border-chat" },
        [
          _vm.getToken && _vm.$route.fullPath.includes("knowledgeBase")
            ? _c("ChatBot", { key: _vm.getToken })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }