var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "disk-content-container" }, [
    _c(
      "div",
      { staticClass: "folder-table-area" },
      [
        _c(
          "div",
          {
            staticClass: "operate-btn",
            class: { showBtn: !!this.selectFileList.length },
          },
          [
            _c("el-checkbox", {
              attrs: { indeterminate: _vm.indeterminate },
              on: { change: _vm.handleCheckAllChange },
              model: {
                value: _vm.isAllChecked,
                callback: function ($$v) {
                  _vm.isAllChecked = $$v
                },
                expression: "isAllChecked",
              },
            }),
            _c("span", [
              _vm._v(" " + _vm._s(_vm.selectFileList.length) + " Selected"),
            ]),
            _c(
              "span",
              { staticClass: "btn", on: { click: _vm.handleBatchMoveInit } },
              [_vm._v("Move")]
            ),
            _c(
              "span",
              {
                staticClass: "btn delete",
                on: { click: _vm.handleBatchDelete },
              },
              [_vm._v("Delete")]
            ),
          ],
          1
        ),
        _c(
          "el-table",
          {
            ref: "multipleTable",
            staticClass: "is-desktop",
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              "tooltip-effect": "dark",
              "show-header": !_vm.selectFileList.length,
              "row-class-name": _vm.tableRowClassName,
              "cell-class-name": _vm.tableCellClassName,
            },
            on: {
              "cell-mouse-enter": _vm.handleCellMouseEnter,
              "cell-mouse-leave": _vm.handleCellMouseLeave,
              "cell-click": _vm.handleTableRowClick,
            },
          },
          [
            _c(
              "el-table-column",
              {
                attrs: { width: "55" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.isChecked || scope.row.isHover
                          ? _c("el-checkbox", {
                              on: {
                                change: function ($event) {
                                  return _vm.handleItemCheckChange(scope.row)
                                },
                              },
                              model: {
                                value: scope.row.isChecked,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "isChecked", $$v)
                                },
                                expression: "scope.row.isChecked",
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "header" },
                  [
                    _c("el-checkbox", {
                      on: { change: _vm.handleCheckAllChange },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
            _c("el-table-column", {
              attrs: { label: "NAME" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.entityType === 1
                        ? _c("i", { staticClass: "el-icon-folder" })
                        : _c("i", { staticClass: "el-icon-tickets" }),
                      _c("span", [_vm._v(_vm._s(scope.row.folderName))]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "folderCreator", label: "CREATOR", width: "150" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createDate",
                label: "CREATION TIME",
                width: "180",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(scope.row.createDate.replace(/-/g, ""))),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "80" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "left",
                            trigger: "hover",
                            "popper-class": "operationPopover",
                            "close-delay": 100,
                          },
                        },
                        [
                          _c("div", { staticClass: "popover-content" }, [
                            scope.row.entityType !== 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "operation-item",
                                    class: { disabled: _vm.downloadLoading },
                                    on: {
                                      click: function ($event) {
                                        !_vm.downloadLoading &&
                                          _vm.handleDownLoad(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-download",
                                    }),
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Download"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "operation-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleInitPermissonData(
                                      scope.row.id
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-lock" }),
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Permission"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "operation-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRename(scope.row)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-edit-outline",
                                }),
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Rename"),
                                ]),
                              ]
                            ),
                            scope.row.entityType === 2
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "operation-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCopyPath(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-copy-document",
                                    }),
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Copy Link"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            scope.row.entityType === 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "operation-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleCopyPathFile(scope.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-copy-document",
                                    }),
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Copy Link"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "operation-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleMove(scope.row)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-right" }),
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Move"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "operation-item delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(scope.row.id)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-delete" }),
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Delete"),
                                ]),
                              ]
                            ),
                          ]),
                          _c("span", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isAllChecked,
                                expression: "!isAllChecked",
                              },
                            ],
                            staticClass: "el-icon-more more-style",
                            class: { notShow: !scope.row.isHover },
                            attrs: { slot: "reference" },
                            slot: "reference",
                          }),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "is-mobile mobile-table",
            staticStyle: { "font-size": "12px" },
          },
          _vm._l(_vm.tableData, function (line) {
            return _c("div", { key: line.id, staticClass: "file" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    width: "100%",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { "flex-grow": "1", overflow: "hidden" },
                      on: {
                        click: function ($event) {
                          return _vm.open(line)
                        },
                      },
                    },
                    [
                      _c("div", { staticStyle: { "font-size": "2em" } }, [
                        line.entityType === 1
                          ? _c("i", { staticClass: "el-icon-folder" })
                          : _c("i", { staticClass: "el-icon-tickets" }),
                      ]),
                      _c("div", { staticClass: "folder-name" }, [
                        _vm._v(_vm._s(line.folderName)),
                      ]),
                      _c("div", [_vm._v(_vm._s(line.folderCreator))]),
                      _c("div", [_vm._v(_vm._s(line.createDate))]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticStyle: { width: "20px" } },
                    [
                      _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            trigger: "click",
                            "popper-class": "operationPopover",
                          },
                        },
                        [
                          _c("div", { staticClass: "popover-content" }, [
                            line.entityType !== 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "operation-item",
                                    class: { disabled: _vm.downloadLoading },
                                    on: {
                                      click: function ($event) {
                                        !_vm.downloadLoading &&
                                          _vm.handleDownLoad(line)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-download",
                                    }),
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("Download"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "operation-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRename(line)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-edit-outline",
                                }),
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Rename"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "operation-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCopyPath(line)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-copy-document",
                                }),
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Copy Path"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "operation-item delete",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelete(line.id)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "el-icon-delete" }),
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("Delete"),
                                ]),
                              ]
                            ),
                          ]),
                          !_vm.isAllChecked
                            ? _c("span", {
                                staticClass: "el-icon-more",
                                staticStyle: {
                                  "font-size": "2em",
                                  transform: "rotate(90deg)",
                                  color: "#9e9e9f",
                                },
                                attrs: { slot: "reference" },
                                slot: "reference",
                              })
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ])
          }),
          0
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "Permission Management",
              visible: _vm.authDialogVisible,
              "append-to-body": "",
              width: "35%",
              "custom-class": "authSettingDialog",
            },
            on: {
              "update:visible": function ($event) {
                _vm.authDialogVisible = $event
              },
              close: _vm.handleAuthClosed,
            },
          },
          [
            _vm.authDialogVisible
              ? _c(
                  "div",
                  { staticClass: "dialog-content" },
                  [
                    _c("AuthPermission", {
                      attrs: { storageFileId: _vm.currentFolderId },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "MOVE",
              visible: _vm.moveDialogVisible,
              "append-to-body": "",
              width: "35%",
              "custom-class": "moveSettingDialog",
            },
            on: {
              "update:visible": function ($event) {
                _vm.moveDialogVisible = $event
              },
              close: _vm.handleMoveClosed,
            },
          },
          [
            _vm.moveDialogVisible
              ? _c(
                  "div",
                  { staticClass: "dialog-content" },
                  [
                    _c("EntityMove", {
                      attrs: { isMultiple: _vm.isMultiple },
                      on: {
                        closeMoveDialogEvent: function ($event) {
                          _vm.moveDialogVisible = false
                        },
                        moveEntityEvent: _vm.handleEntityMoveSave,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "img-preview" },
      [
        _vm.imgViewerVisible
          ? _c("el-image-viewer", {
              ref: "imageViewerref",
              staticClass: "yunPreview",
              class: {
                prevNotShow: _vm.initialIndex === 0,
                nextNotShow: _vm.initialIndex === _vm.originImgList.length - 1,
              },
              attrs: {
                "on-close": _vm.closeImgViewer,
                "url-list": _vm.imgList,
                initialIndex: _vm.initialIndex,
                onSwitch: _vm.testSwitch,
                zIndex: 1000,
                appendToBody: false,
              },
              on: { click: _vm.closeImgViewer },
              nativeOn: {
                mousemove: function ($event) {
                  return _vm.closeImg.apply(null, arguments)
                },
                mousewheel: function ($event) {
                  $event.stopPropagation()
                  return _vm.testWheel.apply(null, arguments)
                },
              },
            })
          : _vm._e(),
        _vm.imgViewerVisible
          ? _c("div", { staticClass: "preview-name" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.originImgList.length
                      ? _vm.originImgList[_vm.initialIndex].folderName
                      : ""
                  )
                ),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }